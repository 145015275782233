var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-screen flex flex-col justify-center items-center px-4"},[_c('van-button',{attrs:{"size":"large","type":"primary","block":""},on:{"click":function () { return _vm.onConfirm(); }}},[_vm._v(" 点餐 ")]),_c('van-button',{staticClass:"mt-8",attrs:{"size":"large","type":"primary","block":""},on:{"click":function($event){_vm.showDatePicker = true}}},[_vm._v(" 预点餐 ")]),_c('van-button',{staticClass:"mt-8",attrs:{"size":"large","type":"primary","block":""},on:{"click":_vm.redirectToOrders}},[_vm._v(" 我的订单 ")]),_c('van-button',{staticClass:"mt-8",attrs:{"size":"large","type":"primary","block":""},on:{"click":_vm.register}},[_vm._v(" 会员注册 ")]),_c('van-button',{staticClass:"mt-8",attrs:{"size":"large","type":"primary","block":""},on:{"click":_vm.recharge}},[_vm._v(" 会员充值 ")]),_c('van-calendar',{attrs:{"min-date":_vm.minDate,"max-date":_vm.maxDate},on:{"confirm":_vm.onConfirm},model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}}),_c('van-action-sheet',{attrs:{"actions":[
      { name: '早餐' },
      {
        name: '午餐'
      },
      {
        name: '晚餐'
      }
    ]},on:{"select":_vm.onSelect},model:{value:(_vm.showTypePicker),callback:function ($$v) {_vm.showTypePicker=$$v},expression:"showTypePicker"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }