<template>
  <div class="h-screen flex flex-col justify-center items-center px-4">
    <van-button size="large" type="primary" block @click="() => onConfirm()">
      点餐
    </van-button>
    <van-button
      class="mt-8"
      size="large"
      type="primary"
      block
      @click="showDatePicker = true"
    >
      预点餐
    </van-button>
    <van-button
      class="mt-8"
      size="large"
      type="primary"
      block
      @click="redirectToOrders"
    >
      我的订单
    </van-button>
    <van-button
      class="mt-8"
      size="large"
      type="primary"
      block
      @click="register"
    >
      会员注册
    </van-button>
    <van-button
      class="mt-8"
      size="large"
      type="primary"
      block
      @click="recharge"
    >
      会员充值
    </van-button>
    <van-calendar
      v-model="showDatePicker"
      @confirm="onConfirm"
      :min-date="minDate"
      :max-date="maxDate"
    />
    <van-action-sheet
      v-model="showTypePicker"
      @select="onSelect"
      :actions="[
        { name: '早餐' },
        {
          name: '午餐'
        },
        {
          name: '晚餐'
        }
      ]"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  data() {
    return {
      showDatePicker: false,
      showTypePicker: false,
      minDate: new Date(
        dayjs()
          .add(1, 'day')
          .valueOf()
      ),
      maxDate: new Date(
        dayjs()
          .add(1, 'week')
          .valueOf()
      )
    }
  },

  mounted() {
    if (this.$store.state.afterCheckout) {
      this.$store.dispatch('resetAfterCheckout')
      this.$router.push({
        name: 'order',
        query: {
          afterOrder: true
        }
      })
    }
  },

  methods: {
    onConfirm(date = new Date()) {
      this.$store.dispatch('setDate', dayjs(date).format('YYYY-MM-DD'))
      this.showDatePicker = false
      this.showTypePicker = true
    },
    onSelect(item) {
      this.$store.dispatch('setOrderType', item)
      this.goMenu()
    },
    goMenu() {
      this.$router.push({
        name: 'menu'
      })
    },
    redirectToOrders() {
      this.$router.push('orders')
    },
    register() {
      this.$router.push('member')
    },
    recharge() {
      this.$router.push('recharge')
    }
  }
}
</script>

<style>
</style>